<template>
    <navbar-index />
    <buyer-features />
    <product-category/>
    <price-quotation />
    <cars-for-sale-home />
    <product-index-home/>
    <service-index-home/>
    <seller-features />
    <shop-index-home />
    <blog-index-home />
    <video-index-home />
</template>
<script>
// import BannerHome from './components/BannerHome.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
import ProductCategory from './components/ProductCategory.vue';
import CarsForSaleHome from './components/CarsForSaleHome.vue'
import ProductIndexHome from './components/ProductIndexHome.vue'
import ServiceIndexHome from './components/ServiceIndexHome.vue'
import ShopIndexHome from './components/ShopIndexHome.vue'
import BlogIndexHome from './components/BlogIndexHome.vue'
import VideoIndexHome from './components/VideoIndexHome.vue'
import BuyerFeatures from './components/BuyerFeatures.vue'
import SellerFeatures from './components/SellerFeatures.vue'
import PriceQuotation from './components/PriceQuotation.vue'
// import BannerIndex from './components/BannerIndex.vue'
import {getAppConfig} from '@/api/appconfig'
import { api_backend } from '@/constants/configs';
import {list as listBanner} from '@/api/banner';
export default {
    name: "HomePage",
    data(){
        return {
            about_us: {},
            testimonials: [],
            features: [],
            api_backend: api_backend,
            host_url: process.env.VUE_APP_BASE_HOST,
            left_banner: null,
            right_banner: null
        }
    },
    components: {
      BlogIndexHome,
      VideoIndexHome,
        NavbarIndex,
        ProductCategory,
        ProductIndexHome,
        ShopIndexHome,
        BuyerFeatures,
        SellerFeatures,
        ServiceIndexHome,
        CarsForSaleHome,
        PriceQuotation
    },
    async created(){
        let res = await getAppConfig();
        if (res != null && res.status == 200 && res.data != null) {
            document.title = res.data.app_config.title;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', res.data.app_config.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', res.data.app_config.keywords);
            this.about_us = JSON.parse(res.data.app_config.about_us);
            this.testimonials = JSON.parse(res.data.app_config.testimonials);
            this.features = JSON.parse(res.data.app_config.features);
        }
        this.getBanner();
    },
    methods: {
        async getBanner() {
            let res = await listBanner('page=1&pagesize=4&type_id=2');
            if (res != null && res.status == 200 && res.data != null && res.data.data != null) {
                this.left_banner = res.data.data[0];
            }
            res = await listBanner('page=1&pagesize=4&type_id=3');
            if (res != null && res.status == 200 && res.data != null && res.data.data != null) {
                this.right_banner = res.data.data[0];
            }
        }
    },
    watch: {
    '$store.getters.permissions': {
        handler(newCart){
            console.log(newCart);
        },
        immediate: true,
        deep: true
    },
  }
};
</script>
<style>
div.sticky-left {
  position: -webkit-sticky;
  position: sticky;
  position: fixed;
  top: 155px;
  padding: 10px;
  font-size: 20px;
  z-index: 10000;
  max-width: 160px;
  left: 90px;
  background-color: #3CB815;
}
div.sticky-right {
  position: -webkit-sticky;
  position: sticky;
  position: fixed;
  top: 155px;
  background-color: #F65005;
  padding: 10px;
  font-size: 20px;
  z-index: 10000;
  max-width: 160px;
  right: 90px;
}
@media screen and (width < 1850px) {
  div.sticky-right, div.sticky-left {
    display: none;
  }
}
</style>