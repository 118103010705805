<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 animated slideInDown">{{ form.fm_selling_field.fm_field.name }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/manager/field-buy-offer">{{ $filters.capitalize($t('title.field-buy-offer')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ form.fm_selling_field.fm_field.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="container-fluid bg-icon mt-5">
        <div class="container">
            <div class="row align-items-center">
                <form>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="selling_price">{{ $filters.capitalize($t('selling-field.total-money')) }}</label>
                                <input v-model="form.selling_price" type="number" min="0" step="1" class="form-control" id="selling_price" @click="clearError">
                                <span class="error text-danger" v-if="errors.selling_price">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.selling_price) }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="deposit">{{ $filters.capitalize($t('selling-field.deposit')) }}</label>
                                <input v-model="form.deposit" type="number" min="0" step="1" class="form-control" id="deposit" @click="clearError">
                                <span class="error text-danger" v-if="errors.deposit">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.deposit) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="start_time">{{ $filters.capitalize($t('selling-field.start-time')) }}</label>
                                <input v-model="form.start_time" type="datetime-local" format="YYYY-MM-DD" class="form-control" id="start_time" @click="clearError">
                                <span class="error text-danger" v-if="errors.start_time">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.start_time) }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="end_time">{{ $filters.capitalize($t('selling-field.end-time')) }}</label>
                                <input v-model="form.end_time" type="datetime-local" format="YYYY-MM-DD" class="form-control" id="end_time" @click="clearError">
                                <span class="error text-danger" v-if="errors.end_time">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.end_time) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="buyer_signature">{{ $filters.capitalize($t('selling-field.buyer-signature')) }}</label>
                                <input v-model="form.buyer_signature" type="text" class="form-control" id="buyer_signature" @click="clearError">
                                <span class="error text-danger" v-if="errors.buyer_signature">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.buyer_signature) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="description">{{ $filters.capitalize($t('selling-field.description')) }}</label>
                                <input v-model="form.description" type="text" class="form-control" id="description" @click="clearError">
                                <span class="error text-danger" v-if="errors.description">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.description) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-md-10">
                            <label for="terms">{{ $filters.capitalize($t('selling-field.terms'))  }}</label>
                            <span class="error text-danger" v-if="errors.terms">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.terms) }}
                                </span>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="addTerm">
                                {{ $filters.capitalize($t('action.add')) }}
                            </button>
                        </div>
                    </div>
                    <div class="row g-3 mt-2" v-for="(item, index) in form.re_terms" v-bind:key="index">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label :for="'key'+index">{{ $filters.capitalize($t('selling-field.term-name')) }}</label>
                                <input :value="item.key" class="form-control" :id="'key'+index" @input="(val) => changeTermKey(val, index)" />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label :for="'value'+index">{{ $filters.capitalize($t('selling-field.term-value')) }}</label>
                                <input :value="item.value" class="form-control" :id="'value'+index" @input="(val) => changeTermValue(val, index)" />
                            </div>
                        </div>
                        <div class="col-md-2 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-danger btn-sm rounded-pill py-1 px-3" type="button" @click="deleteTerm(index)">
                                {{ $filters.capitalize($t('action.delete')) }}
                            </button>
                        </div>
                    </div>
                    <div class="row g-3">
                        <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.field-name')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.area')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.predict-capacity')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.crop-quantity')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.predict-harvest-time')) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ form.fm_selling_field.fm_field.name }}</td>
                            <td>{{ form.fm_selling_field.fm_field.width*form.fm_selling_field.fm_field.length }} (m2)</td>
                            <td>{{ form.fm_selling_field.fm_field.predict_capacity }}</td>
                            <td>{{ form.fm_selling_field.fm_field.crop_quantity }}</td>
                            <td>{{ (new Date(form.fm_selling_field.fm_field.predict_harvest_time * 1000)).toLocaleDateString() }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="row g-3">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="status">{{ $filters.capitalize($t('selling-field.status')) }} {{ $filters.showBuyOfferStatus(form.status) }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="freshForm">{{ $filters.capitalize($t('action.fresh')) }}</button>
                        </div>
                        <div v-if="form.status == 1" class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="updateBuyOffer">{{ $filters.capitalize($t('action.save')) }}</button>
                        </div>
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <a class="btn btn-success btn-sm rounded-pill py-1 px-3" type="button" href="/manager/field-buy-offer">{{ $filters.capitalize($t('action.back')) }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import {detail, edit} from '@/api/field_buy_offer'
export default {
    name: "PageEdit",
    data(){
        return {
            form: {
                selling_field_id: 0,
                selling_price: 0,
                deposit: 0,
                terms: [],
                re_terms: [],
                description: '',
                start_time: (new Date()).toISOString().slice(0,10),
                end_time: (new Date()).toISOString().slice(0,10),
                buyer_signature: '',
                fm_selling_field: {
                    feature_image: '',
                    selling_price: 0,
                    deposit: 0,
                    fm_field: {
                        name: '',
                        crop_quantity: 0,
                        width: 0,
                        length: 0,
                        predict_harvest_time: 0,
                        predict_capacity: 0
                    }
                }
            },
            listStatus: [],
            status: 0,
            total: 0,
            haveError: false,
            errors: {
                selling_price: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            },
            error_titles: {
                selling_price: 'field-buy-offer.selling-price',
                deposit: 'field-buy-offer.deposit',
                description: 'field-buy-offer.description',
                terms: 'field-buy-offer.terms',
                start_time: 'field-buy-offer.start-time',
                end_time: 'field-buy-offer.end-time',
                buyer_signature: 'field-buy-offer.buyer-signature',
            }
        }
    },
    created(){
        this.getDetail();
        // this.getStatus();
    },
    methods: {
        async getDetail() {
            this.$store.state.isLoading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.form = res.data;
                this.form.status = Number(res.data.status);
                this.form.re_terms = [];
                if (this.form.terms != null) {
                    for (const [key, value] of Object.entries(this.form.terms)) {
                        this.form.re_terms.push({
                            key: key,
                            value: value
                        });
                    }
                }
                if (this.form.start_time != null) {
                    let start_time = new Date(this.form.start_time);
                    this.form.start_time = start_time.toISOString().slice(0, 19);
                }
                if (this.form.end_time != null) {
                    let end_time = new Date(this.form.end_time);
                    this.form.end_time = end_time.toISOString().slice(0, 19);
                }
            }
        },
        async updateBuyOffer(){
            if (Number(this.form.selling_price) <= 0) {
                this.haveError = true;
                this.errors.selling_price = true;
            }
            if (Number(this.form.selling_price) <= 0) {
                this.haveError = true;
                this.errors.deposit = true;
            }
            if (Number(this.form.re_terms.length) <= 0) {
                this.haveError = true;
                this.errors.terms = true;
            }
            if (this.form.description.trim() == '') {
                this.haveError = true;
                this.errors.description = true;
            }
            if (this.form.buyer_signature.trim() == '') {
                this.haveError = true;
                this.errors.buyer_signature = true;
            }
            let start_time = new Date(this.form.start_time);
            if (start_time == null || !(start_time instanceof Date)) {
                this.haveError = true;
                this.errors.start_time = true;
                return;
            }
            let end_time = new Date(this.form.end_time);
            if (end_time == null || !(end_time instanceof Date)) {
                this.haveError = true;
                this.errors.end_time = true;
                return;
            }
            if (start_time.getTime() < (new Date()).getTime()) {
                this.haveError = true;
                this.errors.start_time = true;
            }
            if (end_time.getTime() <= start_time.getTime()) {
                this.haveError = true;
                this.errors.end_time = true;
            }
            if (this.haveError) {
                return;
            }
            let formData = new FormData();
            formData.append('selling_price', this.form.selling_price);
            formData.append('deposit', this.form.deposit);
            formData.append('terms', this.form.terms);
            formData.append('description', this.form.description);
            formData.append('buyer_signature', this.form.buyer_signature);
            formData.append('start_time', start_time.getTime()/1000);
            formData.append('end_time', end_time.getTime()/1000);
            if (this.form.re_terms.length > 0) {
                const arrayToObject = {};
                for(let i = 0; i < this.form.re_terms.length; i++) {
                    arrayToObject[this.form.re_terms[i].key] = this.form.re_terms[i].value;
                }
                formData.append('terms', JSON.stringify(arrayToObject));
            }
            this.$store.state.isLoading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.updated-success')),
                        time: 3000,
                        group: 'order'
                    });
            } else {
                this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.updated-failure')),
                        time: 3000,
                        group: 'order'
                    });
            }
        },
        addTerm(){
            this.clearError();
            let i = Math.floor(Date.now() / 1000).toString()
            this.form.re_terms.push({
                key: "name_"+i,
                value: "value_"+i
            });
        },
        deleteTerm(index) {
            this.form.re_terms.splice(index, 1);
        },
        changeTermKey(val, index) {
            this.form.re_terms[index].key = val.target.value;
        },
        changeTermValue(val, index) {
            this.form.re_terms[index].value = val.target.value;
        },
        freshForm(){
            this.getDetail();
            this.haveError = false;
            this.errors = {
                selling_price: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            };
        },
        clearError(){
            this.haveError = false;
            this.errors = {
                selling_price: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            };
        }
    }
};
</script>
<style>
</style>