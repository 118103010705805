<template>
    <nav id="sidebarMenu" class="d-lg-block sidebar bg-white">
      <div class="position-sticky">
        <div class="list-group list-group-flush mx-0 mt-0">
          <!-- Collapse 1 -->
          <a class="list-group-item list-group-item-action py-2" aria-current="true" href="/shop">
              <span>{{ $filters.capitalize($t('news.categories')) }}</span>
          </a>
          <!-- Collapsed content -->
          <ul id="ul-categories" class="list-group list-group-flush p-0" v-if="!isCategory">
            <li v-for="(item, index) in categories" class="list-group-item py-2 px-0 d-md-inline-block" v-bind:key="index">
                  <a :href="'/shop/'+item.slug" class="text-reset d-flex">
                      <img v-if="item.icon != null" class="img-thumbnail" style="width: 40px; height: 40px;" :src=" host_url + item.icon.replace('public', backend+'/storage')" alt="NoImage">
                      <small class="ps-1">{{ item.name}}</small>
                  </a>
            </li>
          </ul>
        <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('title.location'))}}</label>
        <select class="form-control form-control-sm" v-model="location_id" @change="changeLocation" autocomplete="on">
            <option value="0">{{ $filters.capitalize($t('title.all'))}}</option>
            <option v-for="(item, index) in locations" v-bind:key="item.code+index.toString()" :value="item.code">{{ item.name }}</option>
        </select>
        <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('title.ship-methods'))}}</label>
        <select class="form-control form-control-sm" v-model="ship_method" @change="chaneShipMethod">
            <option value="0">{{ $filters.capitalize($t('title.all'))}}</option>
            <option v-for="(item, index) in shipMethods" v-bind:key="item.toString()+index.toString()" :value="item.id">{{ $filters.capitalize($t('title.'+item.name))}}</option>
        </select>
        </div>
      </div>
    </nav>
  </template>
  <script>
  import {list} from '@/api/shop_category';
  import { api_backend } from '@/constants/configs';
  import {list as listProvince} from '@/api/province';
  export default {
      name: "FilterForm",
      props: [
        'changeLocation',
        'changeShipMethod',
        'isCategory'
      ],
      data(){
          return {
              categories: [],
              host_url: process.env.VUE_APP_BASE_HOST,
              backend: api_backend,
              from_price: 0,
              to_price: 100000,
              shops: [],
              shop_id: 0,
              ship_method: 0,
              shipMethods: [
                {
                  id: 1,
                  name: 'express'
                },
                {
                  id: 2,
                  name: 'fast'
                },
                {
                  id: 3,
                  name: 'save-money'
                }
              ],
              locations: [],
              location_id: 0
          };
      },
      created(){
          this.getCategory();
          this.getProvinces();
      },
      methods: {
          async getCategory() {
              let res = await list('page=1&pagesize=100');
              if (res != null && res.status == 200 && res.data != null) {
                  this.categories = res.data.data;
              }
          },
          async getProvinces() {
              let res = await listProvince('page=1&pagesize=100');
              if (res != null && res.status == 200 && res.data != null) {
                  this.locations = res.data.data;
              }
          },
      },
  };
  </script>
  <style>
  #sidebarMenu {
    border-right: solid 1px rgb(228, 226, 226);
  }
  </style>