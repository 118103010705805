import request from '@/utils/request'

function detail(id) {
    return request({
        url: '/fm/selling-field/'+id.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/fm/selling-field?'+query,
        method: 'get',
      })
}
function listByCultivar(query, cultivarId) {
  return request({
      url: '/fm/selling-field?cultivar_id='+cultivarId+'&'+query,
      method: 'get',
    })
}
export {detail, list, listByCultivar};