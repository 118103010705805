<template>
    <a class="product-item" href="#">
        <div class="position-relative bg-light overflow-hidden">
            <img v-if="obj.feature_image != null" class="img-fluid w-100" :src=" host_url + obj.feature_image" alt="NoImage">
        </div>
        <div class="text-center pt-2 text-responsive overflow-hidden">
            <p class="d-block h6 mb-2">{{ obj.name }}</p>
            <span v-if="obj.fm_cultivar != null" class="text-primary me-1 text-responsive">{{ obj.fm_cultivar.name }}</span>
        </div>
    </a>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "ObjectItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    props: [
        'obj'
    ]
}
</script>