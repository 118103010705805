<template>
    <a class="product-item" :href="'/price-quotation/chi-tiet/'+prod.id">
        <div class="text-center pt-2 text-responsive overflow-hidden">
            <p class="d-block h5 mb-2">{{ prod.name }}</p>
            <p class="d-block h6 mb-2">{{ prod.shop.name }}</p>
            <span v-if="prod.from_price > 0" class="text-primary me-1 text-responsive">{{ prod.from_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }} - {{ prod.to_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
            <p class="d-block h6 mb-2">{{ prod.shop.address }}</p>
            <div class="mb-1" v-if="prod.datatime != null">
                <span v-if="prod.datatime != null" class="text-primary me-1">{{ (new Date(prod.datatime*1000)).toLocaleString() }}</span>
            </div>
        </div>
    </a>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "ProductItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    props: [
        'prod'
    ]
}
</script>