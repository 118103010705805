<template>
  <nav id="sidebarMenu" class="d-lg-block sidebar bg-white">
    <div class="position-sticky">
      <div class="list-group list-group-flush mx-0 mt-0">
        <!-- Collapse 1 -->
        <a class="list-group-item list-group-item-action py-2" aria-current="true" href="/san-pham">
            <span>{{ $filters.capitalize($t('news.categories')) }}</span>
        </a>
        <!-- Collapsed content -->
        <ul id="ul-categories" class="list-group list-group-flush p-0" v-if="!isCategory">
          <li v-for="(item, index) in categories" class="list-group-item py-2 px-0 d-md-inline-block" v-bind:key="index">
                <a :href="'/san-pham/'+item.slug" class="text-reset d-flex">
                    <img v-if="item.icon != null" class="img-thumbnail" style="width: 40px; height: 40px;" :src=" host_url + item.icon.replace('public', backend+'/storage')" alt="NoImage">
                    <small class="ps-1">{{ item.name}}</small>
                </a>
          </li>
        </ul>
        <!-- Collapse 1 -->
        <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('product.price'))}}</label>
        <ul id="ul-prices" class="list-group list-group-flush p-0">
            <li class="list-group-item py-2 px-0 d-flex">
              <div class="form-group">
                <label>{{ $filters.capitalize($t('title.from'))}}</label>
                <input type="number" class="form-control form-control-sm d-flex" :value="from_price" :placeholder="$filters.capitalize($t('title.from'))" @change="changeFromPrice">
              </div>
              <div class="form-group">
                <label>{{ $filters.capitalize($t('title.to'))}}</label>
                <input type="number" class="form-control form-control-sm d-flex" :value="to_price" :placeholder="$filters.capitalize($t('title.to'))" @change="changeToPrice">
              </div>
            </li>
          </ul>
          <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('title.shop'))}}</label>
          <select class="form-control form-control-sm" v-model="shop_id" @change="changeShop">
            <option value="0">{{ $filters.capitalize($t('title.all'))}}</option>
            <option v-for="(item, index) in shops" v-bind:key="item.toString()+index.toString()" :value="item.id">{{ item.name }}</option>
          </select>
          <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('title.location'))}}</label>
          <select class="form-control form-control-sm" v-model="location_id" @change="changeLocation" autocomplete="on">
            <option value="0">{{ $filters.capitalize($t('title.all'))}}</option>
            <option v-for="(item, index) in locations" v-bind:key="item.code+index.toString()" :value="item.code">{{ item.name }}</option>
          </select>
          <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('title.ship-methods'))}}</label>
          <select class="form-control form-control-sm" v-model="ship_method" @change="chaneShipMethod">
            <option value="0">{{ $filters.capitalize($t('title.all'))}}</option>
            <option v-for="(item, index) in shipMethods" v-bind:key="item.toString()+index.toString()" :value="item.id">{{ $filters.capitalize($t('title.'+item.name))}}</option>
          </select>
      </div>
    </div>
  </nav>
</template>
<script>
import {list} from '@/api/product_category';
import {list as listShops} from '@/api/shop';
import { api_backend } from '@/constants/configs';
import {list as listProvince} from '@/api/province';
export default {
    name: "FilterForm",
    props: [
      'changeFromPrice',
      'changeToPrice',
      'changeShop',
      'changeLocation',
      'changeShipMethod',
      'isCategory'
    ],
    data(){
        return {
            categories: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            from_price: 0,
            to_price: 0,
            shops: [],
            shop_id: 0,
            ship_method: 0,
            shipMethods: [
              {
                id: 1,
                name: 'express'
              },
              {
                id: 2,
                name: 'fast'
              },
              {
                id: 3,
                name: 'save-money'
              }
            ],
            locations: [],
            location_id: null
        };
    },
    created(){
        this.getCategory();
        this.getShop();
        this.getProvinces();
    },
    methods: {
        async getCategory() {
            let res = await list('page=1&pagesize=100');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async getProvinces() {
            let res = await listProvince('page=1&pagesize=100');
            if (res != null && res.status == 200 && res.data != null) {
                this.locations = res.data.data;
            }
        },
        async getShop() {
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
    },
};
</script>
<style>
#sidebarMenu {
  border-right: solid 1px rgb(228, 226, 226);
}
</style>