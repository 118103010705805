<template>
    <a class="product-item" :href="'/selling-field/chi-tiet/'+prod.id">
        <div class="position-relative bg-light overflow-hidden">
            <img v-if="prod.feature_image != null" class="img-fluid w-100" :src=" host_url + prod.feature_image" alt="NoImage">
            <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">{{ $filters.capitalize($t('title.new')) }}</div>
        </div>
        <div class="text-center pt-2 text-responsive overflow-hidden">
            <p class="d-block h6 mb-2">{{ prod.fm_field.name }}</p>
            <span v-if="prod.selling_price > 0" class="text-primary me-1 text-responsive">{{ prod.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
            <span v-else class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('title.contact')) }}</span>
        </div>
    </a>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "ProductItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    props: [
        'prod'
    ]
}
</script>