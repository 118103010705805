<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 animated slideInDown">{{ form.fm_field.name }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/manager/field-sales-contract">{{ $filters.capitalize($t('title.field-sales-contract')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ form.fm_field.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="container-fluid bg-icon mt-5">
        <div class="container">
            <div class="row align-items-center">
                <form>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="total">{{ $filters.capitalize($t('selling-field.total-money')) }}</label>
                                <input v-model="form.total" type="number" min="0" step="1" class="form-control" id="total" @click="clearError">
                                <span class="error text-danger" v-if="errors.total">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.total) }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="deposit">{{ $filters.capitalize($t('selling-field.deposit')) }}</label>
                                <input v-model="form.deposit" type="number" min="0" step="1" class="form-control" id="deposit" @click="clearError">
                                <span class="error text-danger" v-if="errors.deposit">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.deposit) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="start_time">{{ $filters.capitalize($t('selling-field.start-time')) }}</label>
                                <input v-model="form.start_time" type="datetime-local" format="YYYY-MM-DD" class="form-control" id="start_time" @click="clearError">
                                <span class="error text-danger" v-if="errors.start_time">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.start_time) }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="end_time">{{ $filters.capitalize($t('selling-field.end-time')) }}</label>
                                <input v-model="form.end_time" type="datetime-local" format="YYYY-MM-DD" class="form-control" id="end_time" @click="clearError">
                                <span class="error text-danger" v-if="errors.end_time">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.end_time) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="buyer_signature">{{ $filters.capitalize($t('selling-field.buyer-signature')) }}</label>
                                <input v-model="form.buyer_signature" type="text" class="form-control" id="buyer_signature" @click="clearError">
                                <span class="error text-danger" v-if="errors.buyer_signature">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.buyer_signature) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="description">{{ $filters.capitalize($t('selling-field.description')) }}</label>
                                <input v-model="form.description" type="text" class="form-control" id="description" @click="clearError">
                                <span class="error text-danger" v-if="errors.description">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.description) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-md-10">
                            <label for="terms">{{ $filters.capitalize($t('selling-field.terms'))  }}</label>
                            <span class="error text-danger" v-if="errors.terms">
                                  {{ $filters.capitalize($t('title.please-input')) }}  {{ $t(error_titles.terms) }}
                                </span>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="addTerm">
                                {{ $filters.capitalize($t('action.add')) }}
                            </button>
                        </div>
                    </div>
                    <div class="row g-3 mt-2" v-for="(item, index) in form.re_terms" v-bind:key="index">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label :for="'key'+index">{{ $filters.capitalize($t('selling-field.term-name')) }}</label>
                                <input :value="item.key" class="form-control" :id="'key'+index" @input="(val) => changeTermKey(val, index)" />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label :for="'value'+index">{{ $filters.capitalize($t('selling-field.term-value')) }}</label>
                                <input :value="item.value" class="form-control" :id="'value'+index" @input="(val) => changeTermValue(val, index)" />
                            </div>
                        </div>
                        <div class="col-md-2 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-danger btn-sm rounded-pill py-1 px-3" type="button" @click="deleteTerm(index)">
                                {{ $filters.capitalize($t('action.delete')) }}
                            </button>
                        </div>
                    </div>
                    <div class="row g-3">
                        <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.field-name')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.area')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.predict-capacity')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.crop-quantity')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('selling-field.predict-harvest-time')) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ form.fm_field.name }}</td>
                            <td>{{ form.fm_field.width*form.fm_field.length }} (m2)</td>
                            <td>{{ form.fm_field.predict_capacity }}</td>
                            <td>{{ form.fm_field.crop_quantity }}</td>
                            <td>{{ (new Date(form.fm_field.predict_harvest_time * 1000)).toLocaleDateString() }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="row g-3">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="status">{{ $filters.capitalize($t('selling-field.status')) }}</label>
                                <select class="form-control form-control-sm" placeholder="Chọn trạng thái" v-model="status">
                                    <option v-for="(item, index) in listStatus" v-bind:key="item.val.toString()+index.toString()" :value="item.val">{{ item.label }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="freshForm">{{ $filters.capitalize($t('action.fresh')) }}</button>
                        </div>
                        <div v-if="form.status == 1" class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="updateBuyOffer">{{ $filters.capitalize($t('action.save')) }}</button>
                        </div>
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <a class="btn btn-success btn-sm rounded-pill py-1 px-3" type="button" href="/manager/field-buy-offer">{{ $filters.capitalize($t('action.back')) }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import {detail, edit} from '@/api/field_buy_contract'
export default {
    name: "PageEdit",
    data(){
        return {
            form: {
                status: 0,
                selling_field_id: 0,
                total: 0,
                deposit: 0,
                terms: [],
                re_terms: [],
                description: '',
                start_time: (new Date()).toISOString().slice(0,10),
                end_time: (new Date()).toISOString().slice(0,10),
                buyer_signature: '',
                fm_field: {},
                fm_field_buy_offer: {}
            },
            status: 0,
            total: 0,
            haveError: false,
            errors: {
                total: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            },
            error_titles: {
                total: 'field-buy-offer.selling-price',
                deposit: 'field-buy-offer.deposit',
                description: 'field-buy-offer.description',
                terms: 'field-buy-offer.terms',
                start_time: 'field-buy-offer.start-time',
                end_time: 'field-buy-offer.end-time',
                buyer_signature: 'field-buy-offer.buyer-signature',
            },
            listStatus: [
                {
                    val: 0,
                    label: 'inactive'
                },
                {
                    val: 1,
                    label: 'signed'
                },
                {
                    val: 2,
                    label: 'request_doing'
                },
                {
                    val: 3,
                    label: 'doging'
                },
                {
                    val: 4,
                    label: 'request_finished'
                },
                {
                    val: 5,
                    label: 'finished'
                },
                {
                    val: 6,
                    label: 'request_broken'
                },
                {
                    val: 7,
                    label: 'broken'
                },
            ]
        }
    },
    created(){
        this.getDetail();
        // this.getStatus();
    },
    methods: {
        async getDetail() {
            this.$store.state.isLoading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.form = res.data;
                this.form.status = Number(res.data.status);
                this.form.re_terms = [];
                if (this.form.terms != null) {
                    for (const [key, value] of Object.entries(this.form.terms)) {
                        this.form.re_terms.push({
                            key: key,
                            value: value
                        });
                    }
                }
                if (this.form.start_time != null) {
                    let start_time = new Date(this.form.start_time);
                    this.form.start_time = start_time.toISOString().slice(0, 19);
                }
                if (this.form.end_time != null) {
                    let end_time = new Date(this.form.end_time);
                    this.form.end_time = end_time.toISOString().slice(0, 19);
                }
            }
        },
        async updateBuyOffer(){
            let formData = new FormData();
            formData.append('status', this.form.status);
            this.$store.state.isLoading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.updated-success')),
                        time: 3000,
                        group: 'order'
                    });
            } else {
                this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.updated-failure')),
                        time: 3000,
                        group: 'order'
                    });
            }
        },
        freshForm(){
            this.getDetail();
            this.haveError = false;
            this.errors = {
                total: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            };
        },
        clearError(){
            this.haveError = false;
            this.errors = {
                total: false,
                deposit: false,
                description: false,
                terms: false,
                start_time: false,
                end_time: false,
                buyer_signature: false,
            };
        }
    }
};
</script>
<style>
</style>