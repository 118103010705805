import request from '@/utils/request'

function detail(id) {
    return request({
        url: '/fm/buy-product/'+id.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/fm/buy-product?'+query,
        method: 'get',
      })
}
function related(query, shopId) {
  return request({
      url: '/fm/buy-product?'+query+"&shop_id="+shopId,
      method: 'get',
    })
}
export {detail, list, related};