<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 mb-3 animated slideInDown">{{ $filters.capitalize($t('title.profile')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.profile')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Contact Start -->
    <div class="container-xxl py-6">
        <div class="container">
            <div class="row g-5 justify-content-center">
                <div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <form>
                        <div class="container d-flex align-items-center justify-content-center">
                            <div v-bind="getRootProps()">
                                <input v-bind="getInputProps()" @change="addfile" accept="image/*" autocomplete="on" />
                                <img :src="avatar != null ? avatar : '/img/testimonial-1.jpg'" class="rounded-circle" style="width: 150px; height: 150px;" alt="Avatar" id="image_preview" />
                                <p v-if="isDragActive"></p>
                                <p v-else></p>
                            </div>
                            <br/>
                            <button class="btn btn-primary rounded-pill py-2 px-5 btn-sm" @click.prevent="submitAvatar">{{ $filters.capitalize($t('action.upload')) }}</button>
                        </div>
                        <div class="row g-3 mt-5">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">{{ $filters.capitalize($t('title.email')) }}</label>
                                    <input v-model="email" type="email" class="form-control-plaintext" id="email" readonly>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="name">{{ $filters.capitalize($t('title.fullname')) }}</label>
                                    <input v-model="name" type="text" class="form-control-plaintext" id="name" readonly>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
</template>
<script>
import { profile, edit } from '@/api/user';
import { useDropzone } from "vue3-dropzone";
import { api_backend } from '@/constants/configs';
let file = null;
export default {
    name: "ProfilePage",
    setup(){
        function onDrop(acceptFiles, rejectReasons) {
            file = acceptFiles[0];
            console.log(rejectReasons);
        }
        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
        return {
            getRootProps,
            getInputProps,
            ...rest,
        };
    },
    data(){
        return {
            isLoading: false,
            email: "",
            name: "",
            avatar: "",
            haveError: false,
            file: null,
            backend: api_backend
        }
    },
    async created(){
        await this.$store.dispatch('csrf/getCsrf');
        if (this.$store.getters.csrf_token) {
            document.title = "Profile";
            const csrfEl = document.querySelector('head meta[name="csrf-token"]');
            csrfEl.setAttribute('content', this.$store.getters.csrf_token);
        }
        this.getProfile();
    },
    methods: {
        async getProfile(){
            let res = await profile();
            if (res != null && res.status == 200 && res.data != null) {
                this.email = res.data.email;
                this.name = res.data.name;
                this.avatar = res.data.avatar != null && res.data.avatar != '' ? process.env.VUE_APP_BASE_HOST + res.data.avatar : null;
            }
        },
        async submitAvatar(){
            if (file != null) {
                let data = new FormData();
                data.append('avatar', file);
                let res = await edit(data);
                if (res != null && res.status == 200 && res.data != null) {
                    this.avatar = res.data.avatar;
                    this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.updated-success')),
                        time: 3000,
                        group: 'login'
                    });
                    file = null;
                } else {
                    this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.updated-failure')),
                        time: 3000,
                        group: 'login'
                    });
                    file = null;
                }
            }
        },
        clearError(){
            this.haveError = false;
        },
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
        },
        addfile(e){
            console.log(e.target.files[0]);
            var tmppath = URL.createObjectURL(e.target.files[0]);
            const descEl = document.getElementById('image_preview');
            descEl.setAttribute('src', tmppath);
        }
    },
};
</script>
<style>
</style>