import request from '@/utils/request'

function detail(id) {
    return request({
        url: '/fm/cultivar/'+id.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/fm/cultivar?'+query,
        method: 'get',
      })
}
export {detail, list};