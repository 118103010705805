import request from '@/utils/request'

function detail(id) {
    return request({
        url: '/fm/field-buy-offer/'+id.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/fm/field-buy-offer?'+query,
        method: 'get',
      })
}
function del(id){
    return request({
        url: '/fm/field-buy-offer/'+id,
        method: 'delete'
    });
}
function edit(id, data){
    return request({
        url: '/fm/field-buy-offer/'+id,
        method: 'post',
        data
    });
}
function add(data){
    return request({
        url: '/fm/field-buy-offer',
        method: 'post',
        data
    });
}
export {detail, list, add, edit, del};