import { createApp } from 'vue'
import App from './App.vue'
import VueCookies from 'vue3-cookies'
import router from './router'
import store from './store'
import FlashMessage from '@smartweb/vue-flash-message'
import CKEditor from '@ckeditor/ckeditor5-vue';
import i18n from './i18n';
const app = createApp(App)
app.use(VueCookies)
app.use(FlashMessage)
app.use(store)
app.use(i18n());
app.use(router)
app.use(CKEditor)
app.config.globalProperties.$filters = {
    capitalize(value){
        if (!value) return value;
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    showServiceRequestStatus(value){
        switch(value) {
            case 0:
                return 'created';
            case 1:
                return 'checking';
            case 2:
                return 'accepted';
            case 3:
                return 'rejected';
            default:
                return 'cancel';
        }
    },
    showBuyOfferStatus(value){
        switch(value) {
            case 0:
                return 'inactive';
            case 1:
                return 'created';
            case 2:
                return 'approved';
            case 3:
                    return 'rejected';
            default:
                return 'cancel';
        }
    },
    showSalesContractStatus(value){
        switch(value) {
            case 0:
                return 'inactive';
            case 1:
                return 'signed';
            case 2:
                return 'request_doing';
            case 3:
                return 'doing';
            case 4:
                return 'request_finished';
            case 5:
                return 'finished';
            case 6:
                return 'request_broken';
            case 7:
                    return 'broken';
            default:
                return 'cancel';
        }
    }
};
// Mounting the application to an HTML element with id 'app'
app.mount('#app')
